//定数宣言
import { define } from './util';
//break point 01
define('replaceWidth01', 768);
//break point 02
define('replaceWidth02', 500);

//smooth scroll
import { smoothScroll } from './smoothScroll';
smoothScroll();

//slick slider読み込み
import slick from 'slick-carousel';
import slickInitialise from "./slider";
slickInitialise();


//accordion読み込み
import * as accordion from './accordion';

//hamburger menu 制御用script読み込み
import * as hamburger from './hamburger';

//form control用のjs読み込み
import * as form from './form';
window.onlyNumbers = form.onlyNumbers;
window.moveNextField = form.moveNextField;


//dom をすべて読み終えた後に発火
function domFinished(){
  accordion.accordion();
  hamburger.hamburger();

}

//dom をすべて読み終えたときのイベント追加
document.addEventListener('DOMContentLoaded', domFinished);



// scssを読み込む
import "./scss/styles.scss";