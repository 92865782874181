// slick slider set
export default function(){
  $(function(){
    //Slider used on the top page
    $('.js-slide01').slick({
      slidesToShow: 1,
      speed:1500,
      fade:false,
      autoplaySpeed: 3000,
      dots: false,
      infinite: true,
      autoplay: true,
      arrows: true,
      centerMode: true,
      variableWidth: true,
      responsive: [{
        breakpoint: replaceWidth01,
          settings: {
            variableWidth: false,
        }
      }]
    });
  });
}