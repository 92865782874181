// hamburger set
function hamburger(){
  const hamburgerTrigger = document.querySelector('.js-hamburger__trigger');
  const bodyAddClass = 'is-hamburger--open';
  const doc = window.document.getElementById('document');
  let timer = 0;
  
  function changeBodyClass (){
    const headerHeight = window.document.getElementById('l-header').clientHeight;
    const hamburgerContentHeight = window.document.querySelector('.l-header__menu__content').clientHeight;
    const lHeaderSearch = window.document.querySelector('.l-header__search').clientHeight;
    const lHeaderGnav = window.document.querySelector('.l-header__gnav').clientHeight;
    
    if(this.parentElement.classList.contains('is-active') == true){
      document.body.classList.add(bodyAddClass);
      if (timer > 0) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        doc.style.height = headerHeight + hamburgerContentHeight + 'px';
      }, 200);
    }else{
      document.body.classList.remove(bodyAddClass);
      doc.style.height = 'auto';
    }
  }
  hamburgerTrigger.addEventListener("click", changeBodyClass);

  window.onresize = function () {
    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      let windowWidth = window.innerWidth;
      if(windowWidth >= replaceWidth01){
        document.body.classList.remove(bodyAddClass);
        hamburgerTrigger.parentElement.classList.remove('is-active');
        doc.style.height = 'auto';
      }
    }, 200);
  };
}
export{hamburger}