// accordion set
function accordion(){
  const accordionTrigger = document.querySelectorAll('.js-accordion__trigger');
  function accordionToggle (){
    const accordionWrap = this.parentElement;
    accordionWrap.classList.toggle('is-active');
  }
  for (let i = 0; i < accordionTrigger.length; i++) {
    accordionTrigger[i].addEventListener("click", accordionToggle);
  }
}
export{accordion}